import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import RegionalOfficeRoutes from "./views/DataSetup/RegionalOffice/RegionalOfficeRoutes";
import CategoriesRoutes from "./views/DataSetup/Categories/CategoriesRoutes";
import DepartmentsRoutes from "./views/DataSetup/Departments/DepartmentsRoutes";
import Cannot_reasonRoutes from "./views/DataSetup/Cannot_reason/Cannot_reasonRoutes";
import UserRoutes from "./views/DataSetup/Users/UserRoutes";
import ComplaintRoutes from "./views/Complaint/CreateComplant/ComplaintRoutes"
import CaseRegisterRoutes from "./views/Complaint/RegisterCase/CaseRegisterRoutes"
import Summon_typesRoutes from "./views/DataSetup/Summon_types/Summon_typesRoutes";
import Call_typesRoutes from "./views/DataSetup/Call_types/Call_typesRoutes";
import Victim_typesRoutes from "./views/DataSetup/Victim_types/Victim_typesRoutes";
import MeetingroomRoutes from "./views/DataSetup/MeetingRooms/MeetingroomRoutes";
import CanCannotProcess from "./views/Complaint/Director/CanCannotProcess/CanCannotProcessRoutes";
import DirectorCanCaseRoutes from "./views/Complaint/Director/CanCase/CanCaseRoutes";
import DirectorCannotCaseRoutes from "./views/Complaint/Director/CannotCase/CannotCaseRoutes";
import DirectorFocalPointCaseRoutes from "./views/Complaint/Director/FocalPointCase/FocalPointCaseRoutes";
import AcceptCaseRoutes from "./views/Complaint/Officer/AcceptCase/AcceptCaseRoutes";
import DirectorRoutes from "./views/Complaint/Director/DirectorAllCaseRoutes";
import MACaseRoutes from "./views/Complaint/MA/MACaseRoutes";
import OfficerCaseRoutes from "./views/Complaint/Officer/OfficerCaseRoutes";

import designationroute from "./views/DataSetup/Designation/Designationroute";
import OrganizationRoute from "./views/DataSetup/Organization/OrganizationRoute";
import ArticlesRoute from "./views/DataSetup/Articles/ArticlesRoute";
import focalpointRoute from "./views/DataSetup/Focalpoint/focalpointRoute";
import referalpointsRoute from "./views/DataSetup/Referalpoints/referalpointsRoute";
import unitroute from "./views/DataSetup/Unit/UnitRoutes";
import TemporryCasesRoutes from "./views/Complaint/TemporryCases/TemporryCasesRoutes";
import OldCasesRoutes from "./views/Complaint/OldCases/OldCasesRoutes";
import SummonSectionRoutes from "./views/Complaint/SummonSection/SummonSectionRoutes";
import TimeSlotroute from "./views/DataSetup/TimeSlots/TimeSlotroute";
import OpinionRoutes from "./views/Complaint/Officer/Opinion/OpinionRoutes";
import ReportRoutes from "./views/Reports/ReportRoutes";
import NotificationRoutes from "./views/Notifications/NotificationRoutes";
import ScheduleRoutes from "./views/Schedule/ScheduleRoutes";
import CalendarRoutes from "./views/Clendar/CalendarRoutes";
import RouteRoutes from "./views/DataSetup/Route/RouteRoutes";
import SopRoutes from "././views/SOP/SopRoutes";
import VisitRoutes from "././views/Visits/VisitRoutes";
import FileRoutes from "./views/DataSetup/Files/FileRoutes";
import CaseFileRoutes from "././views/Complaint/File/CaseFileRoutes";
import SumottoRoutes from "./views/Complaint/Sumotto/SumottoRoutes";
import RecordRoomRoutes from "./views/Complaint/RecordRoom/RecordRoomRoutes";
import AppealRoutes from "./views/Complaint/Appeals/AppealRoutes";
import NonImpRoutes from "./views/Complaint/NonImp/NonImpRoutes";
import TrackRoute from "./views/Track/TrackRoute";
import SearchRoutes from "./views/Search/SearchRoutes";
import NotAcceptCaseRoutes from "./views/Complaint/Director/NotAcceptCase/NotAcceptCaseRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/analytics" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionRoutes,
  ...dashboardRoutes,
  ...RegionalOfficeRoutes,
  ...DepartmentsRoutes,
  ...Cannot_reasonRoutes,
  ...Summon_typesRoutes,
  ...Call_typesRoutes,
  ...Victim_typesRoutes,
  ...MeetingroomRoutes,
  ...CategoriesRoutes,
  ...UserRoutes,
  ...ComplaintRoutes,
  ...CaseRegisterRoutes,
  ...CanCannotProcess,
  ...DirectorCanCaseRoutes,
  ...DirectorCannotCaseRoutes,
  ...DirectorFocalPointCaseRoutes,
  ...AcceptCaseRoutes,
  ...DirectorRoutes,
  ...MACaseRoutes,
  ...OfficerCaseRoutes,
  ...redirectRoute,
  ...designationroute,
  ...OrganizationRoute,
  ...ArticlesRoute,
  ...focalpointRoute,
  ...referalpointsRoute,
  ...unitroute,
  ...TemporryCasesRoutes,
  ...OldCasesRoutes,
  ...SummonSectionRoutes,
  ...TimeSlotroute,
  ...OpinionRoutes,
  ...ReportRoutes,
  ...NotificationRoutes,
  ...ScheduleRoutes,
  ...CalendarRoutes,
  ...RouteRoutes,
  ...SopRoutes,
  ...VisitRoutes,
  ...FileRoutes,
  ...CaseFileRoutes,
  ...SumottoRoutes,
  ...RecordRoomRoutes,
  ...AppealRoutes,
  ...NonImpRoutes,
  ...TrackRoute,
  ...SearchRoutes,
  ...NotAcceptCaseRoutes,
  ...errorRoute,

];

export default routes;
