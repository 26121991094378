import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const VictimTypes = MatxLoadable({
  loader: () => import("./VictimTypes")
})

const Victim_typesRoutes = [
  {
    path: "/datasetup/victim_types",
    component: VictimTypes,
    auth: authRoles.datasetup
  }
];

export default Victim_typesRoutes;